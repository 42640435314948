
import { GetAllFactChecks, GetPartners, ExportFactchecks } from "@/services/FactCheckService";
import { ClaimCategory, Factcheck, FactcheckFilter, Partner } from "@/types";
import { Component, Vue, Watch } from "vue-property-decorator";

import { GetClaimCategories } from "@/services/ClaimCategoryService";
import { ROOT_ACTIONS } from "@/store/actions";

import { useDebounceFn, useScroll } from '@vueuse/core'
import { _computeSegments } from "chart.js/dist/helpers/helpers.segment";
import downloadFile from "js-file-download";

@Component({
  components: {
    FactCheckArticleCard: () =>
      import("@/components/cards/FactcheckArticleCard.vue"),
  },
})
export default class Index extends Vue {
  articles = [] as Factcheck[];
  categories = [] as ClaimCategory[];
  partners = [] as Partner[];

  viewMode = "CARDS";

  headers = [
    { text: "Titel", value: "title", cellClass: "cp" },
    { text: "Gepubliceerd", value: "isPublished" },
    { text: "Datum", value: "date" },
    { text: "Rating", value: "claimReviews[0].textualRating" },
  ];

  dialogs = {
    filter: false,
  };

  factcheckCount = 0;
  isoDateRange = ["2022-01-01", "2025-12-31"];
  showOnPage = 20;

  limit = 30;
  offset = 0;

  loading = false;

  loadingExport = false;

  debouncedArticleFetch = useDebounceFn(async () => {
    await this.fetchArticles();
  }, 500)

  async mounted() {
    await this.fetchArticles();
    this.categories = await GetClaimCategories();
    this.partners = await GetPartners();

    const _vm = this;
    window.onscroll = function (ev: Event) {
      if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
        console.log("bottom");
        _vm.showOnPage += 20;
        _vm.offset += 20;
      }
    };
  }

  async exportFactchecks() {
    this.loadingExport = true;
    const data = await ExportFactchecks()
    downloadFile(data, 'factchecks.csv')
    this.loadingExport = false;
  }

  @Watch("isoDateRange", { immediate: true })
  onDateSelect(range: Array<string>) {
    this.filter.dateRange = [
      new Date(`${range[0]}T00:20:00`),
      new Date(`${range[1]}T00:20:00`),
    ];
  }


  filter: FactcheckFilter = {
    query: "",
    dateRange: [new Date(2020, 1, 1), new Date(2022, 1, 1)],
    numericRating: null,
    category: null,
    partners: [],
  };

  isBeforeStart(d: string) {
    return new Date(`${d}T00:20:00`) > this.filter.dateRange![0];
  }

  @Watch('offset')
  async fetchArticles() {
    this.loading = true;
    let fetch = await GetAllFactChecks(this.filter, this.limit, this.offset);
    this.articles.push(...fetch);
    this.loading = false;
  }

  @Watch('filter.query')
  onQueryChange() {
    this.offset = 0;
    this.articles = [];
    this.debouncedArticleFetch();
  }

  @Watch("filter", { deep: true })
  async onFilterChange() {
    this.articles = await GetAllFactChecks(this.filter, this.limit, this.offset);
  }


  openFactcheck(f: Factcheck) {
    this.$router.push(`/factchecks/${f.id}/overview`);
  }

  clearFilter() {
    this.filter = {
      query: "",
      dateRange: [new Date(2021, 1, 1), new Date(2099, 12, 31)],
      numericRating: null,
      category: null,
      partners: [],
    };
    this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
      color: "info",
      text: "Filter leeggemaakt",
    });
  }
}
